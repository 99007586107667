import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export const Image = ({ props, fixed, Cname }) => {
  const data = useStaticQuery(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "d.png" } }) {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  `)

  return (
    <Img
      imgStyle={{ objectFit: 'contain' }}
      style={{ display: 'grid', position: 'relative' }}
      className={Cname}
      fixed={fixed ? fixed : data.imageSharp.fixed}
    ></Img>
  )
}

export default Image
