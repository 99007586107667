import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const StyledBlob = styled(motion.svg)`
  z-index: -1;
  max-width: 100%;
  height: 100%;
`

const ContactBlob = ({ color, variants }) => (
  <StyledBlob
    variants={variants}
    width="546"
    height="547"
    viewBox="0 0 546 547"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M311.055 0.10774C355.812 1.57251 398.595 13.6608 436.808 37.0025C477.798 62.0402 517.143 92.7524 535.429 137.161C554.529 183.545 542.755 233.95 538.487 283.929C532.747 351.131 550.78 427.993 506.264 478.671C459.931 531.417 379.652 557.82 311.055 542.838C245.069 528.427 209.181 459.599 169.324 405.083C140.649 365.862 138.38 316.196 114.444 273.917C80.9424 214.746 -15.2725 175.421 2.07365 109.676C19.0506 45.3299 116.444 50.8164 179.176 28.5709C222.624 13.1635 264.979 -1.4002 311.055 0.10774Z"
      fill-opacity="0.8"
      fill={`#${color}`}
    />
  </StyledBlob>
)

export default ContactBlob
