import React from "react"

import Layout from "../components/common/Layout"
import { Container } from "../components/globalLayout"

import Header from "../components/sections/Logo"
import Intro from "../components/common/Intro"
import Works from "../components/sections/Works"
import About from "../components/sections/About"
import Contact from "../components/sections/Contact"
import NavbarSide from "../components/common/NavbarSide"
import SEO from "../components/common/SEO"

export default function Home() {
  return (
    <>
      <Layout>
        <SEO title="Home | Studio Dojo" />
        <Container>
          <NavbarSide as="nav" />
          <Intro as="section" />
          <Works as="section" id="works" />
          <About as="article" id="about" />
          <Contact as="section" id="contact" />
        </Container>
      </Layout>
    </>
  )
}
