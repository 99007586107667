import React, { useEffect } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

import ContactBlob from "../../data/svg/ContactBlob"
import SingleImage from "../common/SingleImage"

export const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

const firstIntro = {
  initial: {
    y: 0,
  },
  show: {
    y: 0,
    transition: {
      delay: 2,
      delayChildren: 0.2,
      staggerChildren: 0.08,
      staggerDirection: 1,
    },
  },
}

const letter = {
  initial: {
    opacity: 0,
    y: 5,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.2, ...transition },
  },
}
const blob = {
  initial: {
    opacity: 0,
    scale: 0.2,
    y: 5,
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: { duration: 0.2, ...transition },
  },
}

const StyledSection = styled(motion.section)`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto 1fr;
  margin: 4rem 0 8rem;

  h1 {
    margin-bottom: 42px;
  }

  p {
    grid-column: 1 / 9;
    grid-row: 2;
    margin-bottom: 52px;
    color: ${(props) => props.theme.color.paragraph};
  }

  .about-image {
    grid-column: 3;
  }

  .contact-blob {
    grid-column: 3;
  }

  .contact-text {
    grid-row: 1;

    a {
      text-decoration: none;
    }

    .IG {
      color: #1c5746;
      text-decoration: underline;
      text-underline-offset: 0.4rem;
    }
  }
`
export const StyledButton = styled(motion.Button)`
  position: relative;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  padding: 15px 32px;
  border-radius: 5px;
  font-size: 25px;
  color: ${(props) => props.theme.color.black};
  font-family: ${(props) => props.theme.font.secondary};
  background: ${(props) => props.theme.color.pink};
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-4px);
  }
`

const MotionWrap = styled(motion.section)``

const MotionSpanTitle = styled(motion.span)`
  color: ${(props) => props.theme.color.white};
`

function About() {
  const animation = useAnimation()
  const [contentRefAbout, inView] = useInView({
    triggerOnce: true,
    threshold: 0.9,
  })
  useEffect(() => {
    if (inView) {
      animation.start("animate")
    }
  }, [animation, inView])

  const title = "Contact"
  const contact = `Heb jij een cool project, een tof idee of zie je iets wat je leuk vindt? Neem gerust contact met mij op via de button hieronder of via mijn Instagram`
  return (
    <StyledSection id="contact">
      <div className="contact-text">
        <MotionWrap
          className="contact-text"
          ref={contentRefAbout}
          initial="initial"
          animate={animation}
        >
          <MotionSpanTitle variants={letter}>
            <h1>{title}</h1>
          </MotionSpanTitle>
          <MotionSpanTitle variants={letter}>
            <p>
              {contact}{" "}
              <a
                rel="noopener"
                href="https://www.instagram.com/studio.dojo/"
                className="item"
              >
                <b className="IG">@studio.dojo</b>
              </a>
            </p>

            <StyledButton
              as="a"
              href="mailto:dojo.studiosesh@gmail.com?"
              className="item"
            >
              CONTACT
            </StyledButton>
          </MotionSpanTitle>
        </MotionWrap>
      </div>
    </StyledSection>
  )
}

export default About
