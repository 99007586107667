import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

//Motion Variants

export const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }
export const transitionSub = {
  delay: 2.1,
  duration: 1,
  ease: [0.6, 0.01, -0.05, 0.9],
}

const firstIntro = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.06,
      staggerDirection: 1,
    },
  },
}

const secondIntro = {
  initial: {
    y: 0,
  },
  animate: {
    y: 0,
    transition: {
      delay: 3,
      delayChildren: 0.2,
      staggerChildren: 0.08,
      staggerDirection: 1,
    },
  },
}

export const letter = {
  initial: {
    opacity: 0,
    y: 5,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.2, ...transition },
  },
}
export const letter2 = {
  initial: {
    opacity: 0,
    y: 5,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.2, ...transitionSub },
  },
}

const MotionParent = styled(motion.section)`
  display: block;
  max-width: 90%;

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    max-width: 90%;
  }
  .welcome {
    margin-top: 2em;
    margin-bottom: 3em;
  }
`
const MotionSpan = styled(motion.span)`
  display: inline-block;
  color: ${(props) => props.theme.color.white};
  margin-left: 8px;
`

const Intro = () => {
  const Intro = styled(motion.section)`
    display: block;
    margin: 4rem 0 6rem;
    @media screen and (max-width: 700px) {
      margin: 2rem 0 3rem;
    }
    .sub {
      a {
        text-decoration: none;
      }
      .IG {
        background: #1c5746;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-decoration: underline;
        text-underline-offset: 0.4rem;
        text-decoration-color: #1c5746;
      }
    }
  `

  const myName = "Welkom bij studio DOJO, een illustratie en ontwerpstudio."

  return (
    <Intro>
      <MotionParent className="parent" animate="animate" initial="initial">
        <MotionSpan className="welcome" variants={firstIntro}>
          {myName.split(" ").map((str, index) => (
            <MotionSpan variants={letter} key={index}>
              <h1>{str === " " ? "\u00A0" : str}</h1>
            </MotionSpan>
          ))}
        </MotionSpan>
      </MotionParent>

      <MotionParent className="parent" animate="animate" initial="initial">
        <MotionSpan variants={secondIntro}>
          <MotionSpan variants={letter2}>
            <p className="sub">
              Bekijk mijn illustraties en persoonlijke cadeaus onder ‘works’.
              Als ik iets voor je kan tekenen, stuur mij een berichtje op
              Instagram{" "}
              <a
                rel="noopener"
                href="https://www.instagram.com/studio.dojo/"
                className="item"
              >
                <b className="IG">@studio.dojo </b>
              </a>
              of klik onderaan op de contact knop. Enjoy!{" "}
            </p>
          </MotionSpan>
        </MotionSpan>
      </MotionParent>
    </Intro>
  )
}

export default Intro
