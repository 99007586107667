import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import styled from "styled-components"

const transition = { duration: 2, ease: [0.6, -0.5, 0.01, 0.9] }

const socials = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.09,
      staggerDirection: 1,
      delay: 3,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
}

const item = {
  visible: { opacity: 1, x: 0 },
  hidden: { opacity: 0, x: 22 },
}

const StyledNavbarSide = styled(motion.nav)`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  right: 0;
  z-index: 9;
  background-color: transparent;
  height: 0;

  a {
    color: ${(props) => props.theme.color.heading};
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 800;
    transform: rotate(90deg);
    margin-bottom: 3.5em;

    @media screen and (-webkit-min-device-pixel-ratio: 0) {
    }

    @media (max-width: ${(props) => props.theme.screen.sm}) {
      font-size: 1.2rem;
    }
  }
`

function NavbarSide() {
  return (
    <nav>
      <StyledNavbarSide
        className="social-banner"
        initial="hidden"
        exit="exit"
        animate="visible"
        variants={socials}
        transition={{ ...transition }}
      >
        <Link className="item" to="/works">works</Link>
        <Link className="item" to="/about">about</Link>
      </StyledNavbarSide>
    </nav>
  )
}

export default NavbarSide
