import React, { useEffect } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

import AboutBlob from "../../data/svg/AboutBlob"
import SingleImage from "../common/SingleImage"

export const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

const firstIntro = {
  initial: {
    y: 0,
  },
  show: {
    y: 0,
    transition: {
      delay: 2,
      delayChildren: 0.2,
      staggerChildren: 0.08,
      staggerDirection: 1,
    },
  },
}

const letter = {
  initial: {
    opacity: 0,
    y: 5,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.2, ...transition },
  },
}
const blob = {
  initial: {
    opacity: 0,
    scale: 0.2,
    y: 5,
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: { duration: 0.2, ...transition },
  },
}

const StyledAbout = styled(motion.section)`
  display: grid;
  position: relative;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 100px 1fr;
  margin: 4rem 0 8rem;

  h1 {
    margin-bottom: 42px;
  }

  p {
    grid-column: 1 / 9;
    grid-row: 2;
    color: ${(props) => props.theme.color.paragraph};
  }

  .about-image {
    grid-column: 3;
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 400px;
    margin: 6rem 0 0rem;
    padding: 0 1rem;

    .about-image {
      visibility: hidden;
      margin: auto;
      display: inline-grid;
      grid-column: unset;
      place-content: center;
    }
  }
`

const MotionParent = styled(motion.section)`
  position: relative;
`

const MotionSpanTitle = styled(motion.span)`
  display: inline-block;
  color: ${(props) => props.theme.color.white};
`

function About() {
  const animation = useAnimation()
  const [contentRefAbout, inView] = useInView({
    triggerOnce: true,
    threshold: 0.9,
  })
  useEffect(() => {
    if (inView) {
      animation.start("animate")
    }
  }, [animation, inView])

  const Atitle = "About"
  const about = `Mijn naam is Dominique, een freelance illustrator en ontwerper. Ik houd mij bezig met allerlei vormen van creativiteit, maar 1 ding is zeker: tekenen is mijn passie! In 2020 ben ik begonnen met tekenen op de iPad. Ik moet eerlijk toegeven dat ik eerst sceptisch was over digitaal tekenen, maar na een tijdje oefenen, werd het mijn nieuwe hobby. Als linkshandige zit mijn hand altijd onder de inkt en heb ik talloze keren mijn werk verpest door het uitvegen ervan... Op de iPad gebeurt dit niet en oh wat is dat een voordeel! Kijk gerust rond tussen mijn illustraties en ontwerpen. Naast illustraties ontwerp ik ook persoonlijke cadeaus: geboortebordjes, geboortekaartjes, trouwkaarten en ga zo maar door. Neem contact met mij op als ik iets voor je mag tekenen of ontwerpen.`

  return (
    <StyledAbout id="about">
      <MotionParent ref={contentRefAbout} initial="initial" animate={animation}>
        <MotionSpanTitle className="h1" variants={firstIntro}>
          <MotionSpanTitle variants={letter}>
            <h1>{Atitle}</h1>
          </MotionSpanTitle>
        </MotionSpanTitle>
        <MotionSpanTitle className="about" variants={letter}>
          <p>{about}</p>
        </MotionSpanTitle>
        <AboutBlob
          color="034738"
          animate="animate"
          className="blob"
          variants={blob}
        />
      </MotionParent>
      <SingleImage Cname="about-image" />
    </StyledAbout>
  )
}

export default About
